<template>
  <div>
    <div class="content" style="background:#6096fb" id='pdfDom'>
      <div class="title">高考志愿填报志愿位置推荐表</div>
      <div class="form">
        <div class="info">
          <span> 学员姓名：{{ this.$route.query.name }}</span>
          <span> 成绩：{{ this.$route.query.score }}</span>
          <span> 位次：{{ this.$route.query.ranking }}</span>
          <span> 科类：{{ this.$route.query.is_wenli == 1 ? '文科' : '理科' }}</span>
        </div>

        <div v-for='(v, s) in List' :key="s">
          <div class="dom" id="dom">
            <div v-for="(item, i) in v.data" :key="i">
              <table class="table">
                <tr>
                  <td style="width:200px;background:#f2f2f2">平行志愿{{ word[v.dengji - 1] }}</td>
                  <td style="background:#f2f2f2">[ {{ item.school_id }} ]{{ item.name }}</td>
                  <td style="width:200px;text-align:center">备注</td>
                </tr>
                <tr v-for="(val, k) in item.specialty" :key="k">
                  <td>专业{{ word[k] }} </td>
                  <td>{{ val.specialty_name }}</td>
                </tr>
                <tr v-for="test  in (5 - item.specialty.length)" :key="s + item.school_id + test">
                  <td style="height:35px"></td>
                  <td style="height:35px"></td>
                </tr>
                <tr>
                  <td>调剂 </td>
                  <td>同意</td>
                  <td rowspan="6"></td>
                </tr>
              </table>
              <div v-if="(i - 5) % 6 == 0">
                <div id="page1" style="page-break-after: always"></div>
              </div>
            </div>
          </div>
          <div id="page1" style="page-break-after: always"></div>
        </div>
      </div>

    </div>
    <div class="print">
      <el-button @click="doPrint">下载</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      List: [],
      tableData: [],
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九",'十','十一','十二'],
    };
  },
  created() {
    let dengjis = this.$route.query.dengjiList;
    if (Array.isArray(dengjis)) {
      dengjis.forEach((item) => {
        this.getData(item);
      });
    } else {
      this.getData(dengjis);
    }
  },

  methods: {
    getData(e) {
      let check = JSON.parse(this.$route.query.check);
      check.score = this.$route.query.score;
      check.is_wenli = this.$route.query.is_wenli;
      check.batch = this.$route.query.batch;
      check.dengjiList = this.$route.query.dengjiList;
      check.dengji = e;
      this.$fecth.post("tian_bao/getPdf", check).then((res) => {
        this.tableData = res.data;
        let data = {
          dengji: e,
          data: this.tableData,
        };
        this.List.push(data);
        this.List.sort(function (x, y) {
          return x["dengji"] - y["dengji"];
        });
      });
    },
    doPrint() {
      // window.print();
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.getPdf("#pdfDom");
    },
  },
};
</script>

<style scoped lang='less'>
.content {
  width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 40px 40px;

  .title {
    text-align: center;
    color: white;
    font-size: 28px;
    margin: 20px 0;
  }

  .form {
    background: white;
    padding: 20px 40px;
    border-radius: 7px;
    margin-top: 40px;

    .info {
      span {
        margin-right: 30px;
      }
    }

    .table {
      border: 1px solid rgb(173, 169, 169);
      width: 100%;
      margin-top: 20px;
      border-collapse: collapse;

      td {
        border: 1px solid rgb(173, 169, 169);
        padding: 3px 10px;
      }
    }
  }
}

.print {
  position: fixed;
  bottom: 100px;
  right: 200px;
}
</style>
